<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">All Hampers</h1>
            </div>

        </div>

        <div class="row ">



            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">

                        <div class="button-wrapper" v-show="checkAcl('Edit')">
                            <button type="button" class="btn btn-default float-left mb-3" @click="addHamper"><i class="ion-plus"></i> Add</button>
                        </div>
                        <div class="row">
                            <div class="dropdown col-6">
                                <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{mapZones[ZoneId].Name}}
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li class="dropdown-item"
                                        v-for="item in mapZones" :key="item.Id"
                                        @click="doViewByZone(item.Id)">
                                        <a href="javascript:;">{{item.Name}}</a>
                                    </li>
                                </ul>

                                <!-- <button class="btn btn-default " type="button" id="DefaultHamperItems" title="set default items" v-show="ZoneId!=0" @click="selectHamperDefaultItem();">
            Default Items: {{this.HamperDefaultItems[ZoneId]!=undefined?this.HamperDefaultItems[ZoneId].join(", "):""}}
        </button> -->

                                <button class="btn btn-default " type="button" id="DefaultHamperBoxes" title="set default items" v-show="ZoneId!=0" @click="selectHamperDefaultItem();">
                                    Default Boxes: {{this.HamperDefaultBoxes[ZoneId]!=undefined?this.HamperDefaultBoxes[ZoneId].join(", "):""}}
                                </button>
                            </div>
                            <div class="dropdown col-6 text-right">
                               

                                <MDBRow class="mt-3">
                                    <MDBCol md="9">
                                        Order By:
                                        <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{OrderBy[OrderById].Description}}
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li class="dropdown-item"
                                                v-for="item in OrderBy" :key="item.Id"
                                                @click="doViewByOrder(item.Id)">
                                                <a href="javascript:;">{{item.Description}}</a>
                                            </li>
                                        </ul>
                                    </MDBCol>
                                    <MDBCol md="3" class="text-left">
                                        <MDBCheckbox v-model="ShowGuestOnly" label="Guest Only" />
                                    </MDBCol>
                                </MDBRow>
                            </div>


                        </div>
                        <div class="mt-3 tableparent" >
                            <div class="tablechild">
                                <table class="table table-hover table-bordered" id="datatable">
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Box / Bag</th>
                                            <th>Wine - Sake - Juice</th>
                                            <th>Accessories</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in dataByZone" :key="item.Code" v-show="ShowGuestOnly==0||item.ShowGuest==true">
                                            <td>

                                                {{item.Id}}

                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" @click="editHamper(item)"
                                                   class="editable-click"
                                                   data-bs-toggle="tooltip" data-bs-placement="top"
                                                   title="Edit Hamper">
                                                    {{item.Name}}
                                                </a>
                                                <br />

                                                <br />
                                                <div><span style="font-weight: bold;">Price(VAT) : </span> <span style="color: #ffa900;font-weight:bold;">{{formatMoney(item.Price*HamperVAT)}} vnd</span></div>
                                                <div>Created by <b style="font-weight:bold;color:red">{{item.CreatedBy}}</b> at {{formatDate(item.Created) }}</div>
                                                <div>Updated by <b style="font-weight:bold;color:red">{{item.UpdatedBy}}</b> at {{formatDate(item.Updated) }}</div>
                                                <div v-show="!checkFullAcl('User/IsGuest')">Show Guest: <i :class="(item.ShowGuest==true?'ion-android-checkmark-circle lightgreen-text':'ion-android-cancel red-text')"></i></div>
                                                <div>
                                                    <button type="button" class="btn btn-sm btn-danger" @click="PreviewHamper(item,1)" title="Auto Suggest">
                                                        Print
                                                    </button>
                                                </div>
                                                
                                                <div class="mt-3 text-center">
                                                    <a href="javascript:void(0);" @click="OpenPreviewLink(item)">
                                                        <img v-if="item.HamperImage!=''" :src="item.HamperImage" style="max-height: 500px; max-width: 500px; text-align: left; margin-top: 10px" />
                                                    </a>
                                                </div>
                                                <div class="text-center mt-3">
                                                    <button type="button" class="btn btn-primary btn-sm" @click="editHamper(item,true)" title="Duplicate Hamper" v-show="checkAcl('Edit')">
                                                        <i class="ion-ios-copy p-1"></i>
                                                    </button>

                                                    <button type="button" class="btn btn-danger btn-sm" @click="Remove(item)" title="Edit Hamper" v-show="checkAcl('Edit')&&(item.CreatedById==0||checkFullAcl('User/IsAdmin')||item.CreatedById==userId)">
                                                        <i class="ion-trash-a p-1"></i>
                                                    </button>
                                                </div>
                                            </td>

                                            <!--<td>
                                                <span style="color: #ffa900;font-weight:bold;">{{formatMoney(item.Price*HamperVAT)}}</span>
                                            </td>-->
                                            <td>

                                                <div v-for="itemid in item.BoxIds" :key="'Box'+itemid">
                                                    <hr style=" margin-top: 0px;" /><img :src="dataBoxes[itemid].HamperImage" style="max-height: 100px; max-width: 100px; text-align: left; margin-top: 10px" /><br />
                                                    <div>{{dataBoxes[itemid].Code}} - {{dataBoxes[itemid].Name}} </div>
                                                    <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(dataBoxes[itemid].Price)}} đ</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-for="itemid in item.ProductDetailIds" :key="itemid">
                                                    <template v-if="dataProductsDetail[itemid]!=undefined">
                                                        <hr style=" margin-top: 0px;" />
                                                        <img :src="dataProductsDetail[itemid].HamperImage" style="max-height: 200px; max-width: 200px; text-align: left; margin-top: 10px" />
                                                        <br />
                                                        {{dataProductsDetail[itemid].Code}} - {{mapProductNames[dataProductsDetail[itemid].Code].Name}}
                                                        <br />
                                                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(dataProductsDetail[itemid].Price)}} đ</div>
                                                    </template>
                                                    <template v-if="dataProductsDetail[itemid]==undefined">
                                                        <hr style=" margin-top: 0px;" />
                                                        Data is removed!
                                                    </template>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-for="itemid in item.ItemIds" :key="'Product'+itemid">
                                                    <hr style=" margin-top: 0px;" /><img :src="dataItems[itemid].HamperImage" style="max-height: 100px; max-width: 100px; text-align: left; margin-top: 10px" /><br />
                                                    <div>{{dataItems[itemid].Code}} - {{dataItems[itemid].Name}} </div>
                                                    <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(dataItems[itemid].Price)}} đ</div>
                                                </div>
                                            </td>


                                            
                                            


                                           

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>




                    </div>
                </div>
            </div>

        </div>



        <MDBModal id="editHamperBoxModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showEditHamper"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle v-html="editTitle"></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBCol md="5" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                        <MDBInput label="Name"
                                  id="HamperName"
                                  v-model="Name"
                                  invalidFeedback="Please provide a image"
                                  validFeedback="Looks good!" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="8">
                        <div v-if="!checkFullAcl('User/IsGuest')"><b>COST - NO VAT (Giá Nền - Không VAT):</b> {{formatMoney(HamperOrgPrice)}} đ</div>
                        <div><b>Price without VAT:</b> {{formatMoney(HamperPrice)}} đ</div>
                        <div><b>Price VAT:</b> <span class="red-text" style="font-weight:bold">{{formatMoney(HamperPriceVAT)}} vnd</span></div>
                    </MDBCol>
                    <MDBCol md="4" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                        <div>
                            <button type="button" class="btn btn-primary" @click="ShowAutoSuggest()" title="Auto Suggest">
                                Auto Suggest
                            </button>

                            <button type="button" class="btn btn-danger" @click="PreviewHamper(editData,1)" title="Auto Suggest">
                                Print
                            </button>
                        </div>
                        
                    </MDBCol>
                </MDBRow>

                <!--  <MDBRow class="mt-3">
        <MDBCol md="2">
            Select Zone:
        </MDBCol>
        <MDBCol md="3">
            <div class="dropdown">
                <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{mapZones[editZoneId].Name}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="dropdown-item"
                        v-for="item in mapZones" :key="'editItemZone'+item.Id"
                        v-show="item.Id!=0"
                        @click="selectEditZone(item.Id)">
                        <a href="javascript:;">{{item.Name}}</a>
                    </li>

                </ul>
            </div>
        </MDBCol>

    </MDBRow> -->

                <MDBRow class="mt-3">
                    <MDBCol md="12" class="text-center">
                        <h6>================== Box / Bag ==================</h6>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol md="12" class="text-center" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                        <a href="javascript:;" @click="selectHamperBox" title="add" class="ml-6">
                            <img :src="require(`@/assets/img/app/Add.png`)" />
                        </a>
                        <a style="margin-left:10px" href="javascript:;" @click="clearAllHamperBoxes()" title="Clear All" class="ml-6">
                            <img style="height:48px;" :src="require(`@/assets/img/app/delete.png`)" />
                        </a>
                    </MDBCol>
                    <MDBCol v-for="item in selectedBoxes" style="" :key="item.Id" md="4" class="text-center mt-3">
                        <img :src="item.HamperImage==''?require(`@/assets/img/app/no-image-vertical.jpg`):item.HamperImage" style="cursor: pointer;max-height:100px; max-width: 100px" /><br />
                        <div>{{item.Code}} - {{item.Name}}</div>
                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(item.Price)}} đ</div>
                        <div v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                            <a href="javascript:;" @click="removeBoxFromHamper(item)" title="remove Item" class="ml-6">
                                <img :src="require(`@/assets/img/app/delete.png`)" />
                            </a>
                        </div>
                    </MDBCol>

                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol md="12" class="text-center">
                        <h6>================== Wine / Sake / Juice  ==================</h6>
                    </MDBCol>
                    <MDBCol md="12" class="text-center" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                        <a href="javascript:;" @click="selectHamperBox(false,true)" title="add" class="ml-6">
                            <img :src="require(`@/assets/img/app/Add.png`)" />
                        </a>
                        <a style="margin-left:10px" href="javascript:;" @click="clearAllHamperItem(true)" title="Clear All" class="ml-6">
                            <img style="height:48px;" :src="require(`@/assets/img/app/delete.png`)" />
                        </a>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol v-for="item in selectedProducts" style="" :key="item.Id" md="4" class="text-center mt-3">
                        <img :src="item.HamperImage==''?require(`@/assets/img/app/no-image-vertical.jpg`):item.HamperImage" style="cursor: pointer;max-height:100px; max-width: 100px" /><br />
                        <div>{{item.Code}} - {{mapProductNames[item.Code].Name}}</div>
                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(item.Price)}} đ</div>
                        <div v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                            <a href="javascript:;" @click="removeBoxItem(item,true)" title="remove wine" class="ml-6">
                                <img :src="require(`@/assets/img/app/delete.png`)" />
                            </a>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol md="12" class="text-center">
                        <h6>================== Gift Items / Accessories ==================</h6>
                        <MDBCol md="12" class="text-center" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                            <a href="javascript:;" @click="selectHamperBox(false,false)" title="add" class="ml-6">
                                <img :src="require(`@/assets/img/app/Add.png`)" />
                            </a>
                            <a style="margin-left:10px" href="javascript:;" @click="clearAllHamperItem(false)" title="Clear All" class="ml-6">
                                <img style="height:48px;" :src="require(`@/assets/img/app/delete.png`)" />
                            </a>
                        </MDBCol>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol v-for="item in selectedItems" style="" :key="item.Id" md="4" class="text-center mt-3">
                        <img :src="item.HamperImage==''?require(`@/assets/img/app/no-image-vertical.jpg`):item.HamperImage" style="cursor: pointer;max-height:100px; max-width: 100px" /><br />
                        <div>{{item.Code}} - {{item.Name}}</div>
                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(item.Price)}} đ</div>
                        <div v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                            <a href="javascript:;" @click="removeBoxItem(item,false)" title="remove Item" class="ml-6">
                                <img :src="require(`@/assets/img/app/delete.png`)" />
                            </a>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                    <MDBCol md="12" class="text-center">
                        <h6>================== Other Setting for Hamper ================</h6>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3" v-if="!checkFullAcl('User/IsGuest')&&checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                    <MDBCol md="2">

                    </MDBCol>
                    <MDBCol md="6">
                        <MDBCheckbox v-model="ShowGuest" label="Show Guest" />
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                    <MDBCol md="12">
                        <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFileUpload(event)"><i class="ion-image"></i>Select Preview Image</button>
                        <div class="mt-3" id="uploadFileInfo" style="text-align:center">
                            <img :src="HamperImage" style="max-height:500px;max-width:500px" v-if="HamperImage!=''" />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBCol md="5" v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                    <MDBInput label="Preview Link"
                              id="PreviewLink"
                              v-model="PreviewLink"
                              invalidFeedback="Please provide a image"
                              validFeedback="Looks good!" />
                </MDBCol>
            </MDBModalBody>
            <MDBModalFooter v-if="checkAcl('Edit')&&(editCreatedId==0||checkFullAcl('User/IsAdmin')||editCreatedId==userId)">
                <button type="submit" class="btn btn-success" @click="SaveEdit">
                    Save
                </button>
                <button type="button" class="btn btn-danger" @click="showEditHamper=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


        <MDBModal id="HamperDefaultItemSelectModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showHamperDefaultItemsSelect"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle v-html="selectBoxTitle"> </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBInput label="Search"
                              id="SearchCode"
                              v-model="SearchCode" />
                </MDBRow>
                <MDBRow>
                    <MDBCol v-for="item in dataBoxesAvailable" @click="setDefaultItem(item.Code)" :style="{'cursor':'pointer','border':HamperDefaultItemsPopup[ZoneId]!=undefined&&HamperDefaultItemsPopup[ZoneId].includes(item.Code)?'1px #828287 solid;':'','background-color':HamperDefaultItemsPopup[ZoneId]!=undefined&&HamperDefaultItemsPopup[ZoneId].includes(item.Code)?'lightblue':''}" :key="'HamperDefault'+item.Id" md="4" class="text-center mt-3" v-show="HamperDefaultItemsPopup[ZoneId]!=undefined&&HamperDefaultItemsPopup[ZoneId].includes(item.Code) || (SearchCode==''||item.Name.toLowerCase().indexOf(SearchCode.toLowerCase())>-1||(item.Code&&item.Code.toLowerCase().indexOf(SearchCode.toLowerCase())>-1))">
                        <img :src="item.HamperImage==''?require(`@/assets/img/app/no-image-vertical.jpg`):item.HamperImage" style="max-height:100px; max-width: 100px" /><br />
                        <div>{{item.Code}} - {{item.Name}}</div>
                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(item.Price)}} đ</div>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="saveHamperDefaultItems(true)">
                    Save
                </button>

            </MDBModalFooter>
        </MDBModal>

        <MDBModal id="HamperBoxSelectModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showHamperBoxSelect"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle v-html="selectBoxTitle"></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBInput label="Search"
                              id="SearchCode"
                              v-model="SearchCode" />
                </MDBRow>
                <MDBRow>
                    <MDBCol v-for="item in dataBoxesAvailable" style="" :key="item.Id" md="4" class="text-center mt-3" @click="SelectBox(item)" v-show="SearchCode==''||item.Name.toLowerCase().indexOf(SearchCode.toLowerCase())>-1||(item.Code&&item.Code.toLowerCase().indexOf(SearchCode.toLowerCase())>-1)">
                        <img :src="item.HamperImage==''?require(`@/assets/img/app/no-image-vertical.jpg`):item.HamperImage" style="cursor: pointer;max-height:100px; max-width: 100px" /><br />
                        <div>{{item.Code}} - {{item.Name}}</div>
                        
                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(item.Price)}} đ</div>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-danger" @click="showHamperBoxSelect=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>

        <MDBModal id="HamperAutoSuggestModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showHamperAutoSuggest"
                  size="xl">
            <MDBModalHeader>
                <MDBModalTitle>
                    
                        <div><b>Price:</b> {{formatMoney(HamperPrice)}} đ</div>
                        <div><b>Price VAT:</b> {{formatMoney(HamperPriceVAT)}} đ</div>
                    

                </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow style="margin-top:30px">
                    <MDBCol md="3">
                        # of Wine/Sake/Juice
                    </MDBCol>
                    <MDBCol md="4">
                        <Slider v-model="sliderwine.value" v-bind="sliderwine" />
                    </MDBCol>
                </MDBRow>
                <MDBRow style="margin-top:30px">
                    <MDBCol md="3">
                        # of Hamper items (not Wine/Sake)
                    </MDBCol>
                    <MDBCol md="4">
                        <Slider v-model="slideritem.value" v-bind="slideritem" />
                    </MDBCol>
                </MDBRow>
                <MDBRow style="margin-top:30px">
                    <MDBCol md="3">
                        Price NO VAT
                    </MDBCol>
                    <MDBCol md="9">
                        <Slider v-model="sliderprice.value" v-bind="sliderprice" />
                    </MDBCol>
                </MDBRow>
                <MDBRow style="margin-top:30px">
                    <MDBCol md="3">
                        All Same Wine
                    </MDBCol>
                    <MDBCol md="2">
                        <MDBCheckbox v-model="AllSameWine" />
                    </MDBCol>
                </MDBRow>
               <!--  <MDBRow style="margin-top:30px">
                    <MDBCol md="3">
                        Allow ZERO price's wine
                    </MDBCol>
                    <MDBCol md="2">
                        <MDBCheckbox v-model="zeroPrizeWine" />
                    </MDBCol>
                    <MDBCol md="5" v-show="zeroPrizeWine">
                        <MDBInput label="Number of ZERO price wine"
                                  id="zeroPrizeWineNumber"
                                  v-model="zeroPrizeWineNumber"
                                  type="number"
                                  step=1 />

                    </MDBCol>
                </MDBRow> -->
                <MDBRow style="margin-top:10px">
                    <MDBCol md="3">
                        Number of suggests
                    </MDBCol>
                    <MDBCol md="2">
                        <MDBInput label=""
                                  id="NumOfSuggest"
                                  v-model="NumOfSuggest"
                                  type="number"
                                  step=1 />
                    </MDBCol>
                </MDBRow>
                <MDBRow style="margin-top:20px">
                    <MDBCol md="12" style="text-align:center">
                        <button type="button" class="btn btn-primary" @click="GetSuggest()" title="Get Suggest">
                            Make Hampers
                        </button>
                    </MDBCol>
                </MDBRow>
                <MDBRow style="margin-top:30px">
                    <MDBCol md="12" style="text-align:center">
                        <table class="table table-hover table-bordered table-dark">
                            <thead>
                                <tr>
                                    <th>Wine</th>
                                    <th>Items</th>
                                    <th>
                                        <div v-if="!checkFullAcl('User/IsGuest')">COST - NO VAT (Giá Nền - Không VAT) / </div> 
                                        <div>Price No VAT /</div>
                                        <div>Price with VAT</div>
                                        </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in dataSuggest" :key="'dataSuggest'+item.Id">
                                    <td>
                                        <div v-for="itemid in item.ProductDetailIds" :key="'dataSuggestProductDetailIds'+item.Id" style="text-align:left;margin-top:10px">
                                            - <img :src="dataProductsDetail[itemid].HamperImage" style="max-height:30px;" />{{dataProductsDetail[itemid].Code}} - {{mapProductNames[dataProductsDetail[itemid].Code].Name}} <span v-show="!checkFullAcl('User/IsGuest')">- {{formatMoney(dataProductsDetail[itemid].Price)}}đ</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-for="itemid in item.ItemIds" :key="'dataSuggestItemIds'+item.Id" style="text-align:left;margin-top:10px">
                                            - <img :src="dataItems[itemid].HamperImage" style="max-height:30px;" />{{dataItems[itemid].Code}} - {{dataItems[itemid].Name}} <span v-show="!checkFullAcl('User/IsGuest')">- {{formatMoney(dataItems[itemid].Price)}}đ</span>
</div>
                                    </td>
                                    <td>
                                        <div v-if="!checkFullAcl('User/IsGuest')">{{formatMoney(item.OrgPrice)}} đ</div>
                                        <div>{{formatMoney(item.Price)}} đ</div>
                                        <div>{{formatMoney(item.PriceVAT)}} đ</div>
                                        <div>
                                            <button type="button" class="btn btn-primary btn-sm" @click="SelectSuggest(item)" title="Get Suggest">
                                                Select
                                            </button>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-danger" @click="showHamperAutoSuggest=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>

    </div>
</template>
<style>
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }
    .slider-horizontal {
        margin-top: 10px;
    }
    .tableparent {
        transform: rotateX(180deg);
        overflow-x: auto;
    }

    .tablechild {
        padding-top:10px;
        transform: rotateX(180deg);
        
    }
    .editable-click, a.editable-click {
        border-bottom: none;
    }
</style>
<style src="@vueform/slider/themes/default.css"></style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import 'datatables.net-responsive'

    //https://www.vuescript.com/custom-range-slider/
    //https://vueform.com/reference/1.x/slider-element#sliderelement
    import Slider from '@vueform/slider'

    


    import c from '@/mylib/common'
    import { mapState, mapActions } from 'vuex'

    import {
        //MDBBtn,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBRow, MDBCol, MDBInput, MDBTextarea,
        MDBCheckbox,
        MDBModalFooter,
        MDBTooltip
    } from 'mdb-vue-ui-kit';


    export default {
        name: `Hamper`,
        data: function () {
            return {                
                editTitle: '',
                editId: 0,
                editCreatedId:0,
                editZoneId: 0,
                
                ZoneId: 0,
                HamperVAT: 1.1,
                HamperDefaultItemsPopup: {},
                HamperDefaultItems: {},
                HamperDefaultBoxes: {},
                editData: {},
                data: [],     
                dataByZone: [],

                dataBoxes: {},
                dataItems: {},
                mapZones: { 0: { 'Id': 0, 'Name': '-- All Regions --' } },
                dataProductsDetail: {},
                mapProducts: {},
                mapItems: {},
                mapBoxes: {},
                mapProductNames: {},
                mapProductType: {},
                mapBoxOrgPrice: {},
                mapProdOrgPrice: {},
                mapItemOrgPrice: {},
                dataTable: null,       
                showEditHamper: false,
                //=========for order by
                OrderBy: {
                    0: { 'Id': 0, 'Description': 'Code' },
                    1: { 'Id': 1, 'Description': 'Name Descending ↓' },
                    2: { 'Id': 2, 'Description': 'Name Ascending ↑' },
                    3: { 'Id': 3, 'Description': 'Price Descending ↓' },
                    4: { 'Id': 4, 'Description': 'Price Ascending ↑' },
                    5: { 'Id': 5, 'Description': 'Newer Update' },
                    6: { 'Id': 6, 'Description': 'Older Update' },
                },
                OrderById: 5,
                ShowGuestOnly:0,
                //=========for hamper setup
                HamperPrice: '0',
                HamperPriceVAT: '0',
            HamperOrgPRice:'0',
                Name: '',
                PreviewLink:'',
                ShowGuest: false,
                FileUpload: {},
                hamperVolume: 0,
                dataBoxesAvailable:[],
                selectedItems: [],
                selectedProducts: [],
                selectedBoxes: [],
                SearchCode: '',                
                showHamperBoxSelect: false,
                showHamperDefaultItemsSelect:false,
                showHamperAutoSuggest: false,
                PreviewWindow:null,
                //==========for select box
                selectBoxTitle: '',
                lastNumberAdded: 0,
                isSelectBox: true,
                //============ for suggestion
                dataSuggest: [],
                NumOfSuggest: 10,
                AllSameWine: true,
                suggestDuplicate: false,
                zeroPrizeWine: false,
                zeroPrizeWineNumber:1,
                //============ slider
                sliderwine: {
                    value: 10,                    
                    min: 1,
                    max: 6,
                    format: {                        
                        suffix:' bottles',
                        decimals: 0
                    },
                },
                slideritem: {
                    value: 10,
                    min: 1,
                    max: 6,
                    format: {
                        suffix: ' items',
                        decimals: 0
                    },
                },
                sliderprice: {
                    value: [1000000, 10000000],
                    merge: 50000000,
                    step:100000,
                    min:100000,
                    max:7000000,
                    format: {
                        //prefix: 'đ',
                        suffix: ' đ',
                        decimals: 0,
                        thousand: '.',                        
                    },
                },
            }
        },       
        components: {
            Slider,
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBRow, MDBCol, MDBInput, MDBTextarea,
            MDBCheckbox,
            MDBModalFooter,
            MDBTooltip
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
            timeOffset: state => state.session.timeOffset,
            userId: state => state.session.userid,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {                
                return this.$store.state.session.acl.includes(router);
            },
            start() {

                this.getItems();
               
            },
            formatDate(dstr, notime) {
                if (dstr == null || dstr == "") return 'N/A';

                try {
                    var d = new Date(dstr);
                    d.setTime(d.getTime() - this.timeOffset * 60 * 1000);
                    return c.formatDate(new Date(d), notime);
                } catch (ex) {
                    console.log(ex);
                    return 'N/A';
                }
            },
            
            formatMoney(money, symbol, decCount) {
                symbol = symbol || '';
                decCount = decCount || 0;

                const rs = c.formatMoney(money, symbol, decCount);
                return rs;
            },
            ViewHistory(code,name) {
                if (this.checkAcl('ViewHistory'))
                    this.$store.dispatch("view/viewHistory", { code: code,name:name });
            },
            addHamper() {                
                if (!this.checkFullAcl('Hamper/Edit')) {
                    this.showToast({ isShow: true, title: '', content: "You have no permission!", type: 'error' });
                    return;
                }
                if (this.ZoneId == 0) {
                    this.showToast({ isShow: true, title: '', content: "Please select a Region!", type: 'error' });
                    return;
                }

                this.editId = 0;
                this.editCreatedId = 0;
                this.editZoneId = this.ZoneId;
                this.editData = { Id: 0, Name: '', HamperSizeX: '0', HamperSizeY: '0', Price: '0' };
                this.editTitle = 'Setup new Hamper';
                this.HamperPrice = 0;
                this.HamperPriceVAT = 0;
                this.Name = '';
                this.PreviewLink = '';
                this.ShowGuest = this.checkFullAcl('User/IsGuest')?true: false;
                //reset value
                this.hamperVolume = 0;
                
                this.selectedBoxes = [];
                //auto add default box
                if (this.HamperDefaultBoxes[this.ZoneId] != undefined) {
                    let ItemCodes = this.HamperDefaultBoxes[this.ZoneId];                
                    for (let i = 0; i < ItemCodes.length; i++) {
                        if (ItemCodes[i] != '' && this.mapBoxes[ItemCodes[i]] != undefined) {
                            this.lastNumberAdded++;                            
                            let itemadd = JSON.parse(JSON.stringify(this.mapBoxes[ItemCodes[i]]));
                            itemadd.NumberAdded = this.lastNumberAdded;
                            this.selectedBoxes.push(itemadd);
                        }
                    }
                }

                this.selectedProducts = [];
                //auto add default items
                this.selectedItems = [];
                if (this.HamperDefaultItems[this.ZoneId] != undefined) {
                    let ItemCodes = this.HamperDefaultItems[this.ZoneId];                    
                    for (let i = 0; i < ItemCodes.length; i++) {
                        if (ItemCodes[i] != '' && this.mapItems[ItemCodes[i]] != undefined) {                            
                            this.lastNumberAdded++;                        
                            let itemadd = JSON.parse(JSON.stringify(this.mapItems[ItemCodes[i]]));
                            itemadd.NumberAdded = this.lastNumberAdded;
                            this.selectedItems.push(itemadd);
                            
                        }
                    }
                }
                this.recalculateHamperPrice();
                this.showEditHamper = true;
            },
            
            editHamper(item, isDuplicated) {
                if (isDuplicated == undefined) isDuplicated = false;
                if (!this.checkFullAcl('Hamper/Edit')) {
                    this.showToast({ isShow: true, title: '', content: "You have no permission!", type: 'error' });
                    return;
                }
                this.editData = JSON.parse(JSON.stringify(item));
                this.HamperPrice = item.Price;
                this.HamperPriceVAT = item.Price * this.HamperVAT;
                this.editId = isDuplicated?0: item.Id;
                this.editCreatedId = isDuplicated ? 0 : item.CreatedById;
                this.editZoneId = item.ZoneId;
                this.editTitle = isDuplicated ? 'Duplicate ' + item.Name : item.Name;
                this.Name = isDuplicated ? 'Duplicate ' + item.Name : item.Name;
                this.PreviewLink = item.PreviewLink == null ? '' : item.PreviewLink;
                this.FileUpload = {};
                this.ShowGuest = item.ShowGuest == null ? false : item.ShowGuest;
                this.editData["HamperSizeX"] = this.editData["HamperSizeX"] + '';
                this.editData["HamperSizeY"] = this.editData["HamperSizeY"] + '';
                this.editData["Price"] = this.editData["Price"] + '';


                this.selectedBoxes = [];
                for (let i = 0; i < item.BoxIds.length; i++) {
                if (item.BoxIds[i] != '') {
                        this.lastNumberAdded++;
                        let itemadd = JSON.parse(JSON.stringify(this.dataBoxes[item.BoxIds[i]]));
                        itemadd.NumberAdded = this.lastNumberAdded;
                        this.selectedBoxes.push(itemadd);
                    }
                }
               

                this.selectedProducts = [];                
                for (let i = 0; i < item.ProductDetailIds.length; i++) {
                    if (item.ProductDetailIds[i] != '') {
                        this.lastNumberAdded++;

                        let itemadd = {Code:"Error",Name:"Data is Removed",HamperImage:"",Price:0};
                        if (this.dataProductsDetail[item.ProductDetailIds[i]])
                            itemadd = JSON.parse(JSON.stringify(this.dataProductsDetail[item.ProductDetailIds[i]]));
                        itemadd.NumberAdded = this.lastNumberAdded;
                        this.selectedProducts.push(itemadd);
                    }
                }
                this.selectedItems= [];
                for (let i = 0; i < item.ItemIds.length; i++) {
                    if (item.ItemIds[i] != '') {
                        this.lastNumberAdded++;
                        let itemadd = JSON.parse(JSON.stringify(this.dataItems[item.ItemIds[i]]));
                        itemadd.NumberAdded = this.lastNumberAdded;
                        this.selectedItems.push(itemadd);
                    }
                }
                this.recalculateHamperPrice();
                this.showEditHamper = true;
            },
            SelectFileUpload() {
                var el = window._protected_reference = document.createElement("INPUT");
                el.type = "file";
                //el.accept = ".jpg,.jpeg,.png";
                el.accept = "image/*";
                //el.multiple = "multiple"; // remove to have a single file selection

                // (cancel will not trigger 'change')
                var _this = this;
                el.addEventListener('change', function () {
                    // access el.files[] to do something with it (test its length!)

                    // add first image, if available
                    if (el.files.length) {
                        const fileSize = el.files[0].size / 1024 / 1024; // in MiB
                        if (fileSize > 5) {
                            _this.showToast({ isShow: true, title: '', content: "File size exceeds 5 MB", type: 'error' });
                            return;
                        }
                        _this.FileUpload = el.files[0];
                        //document.getElementById('uploadFileInfo').innerHTML = _this.FileUpload.name;
                        document.getElementById('uploadFileInfo').innerHTML = `<img style="max-height:500px;max-width:500px" src="${URL.createObjectURL(el.files[0])}" />`
                    }


                    //// test some async handling
                    //new Promise(function (resolve) {
                    //    setTimeout(function () { console.log(el.files); resolve(); }, 1000);
                    //})
                    //    .then(function () {
                    //        // clear / free reference
                    //        el = window._protected_reference = undefined;
                    //    });

                });
                el.click();
            },
            OpenPreviewLink(item) {                
                
                if (item.PreviewLink != null && item.PreviewLink != '') window.open(item.PreviewLink, item.Name);
                else this.PreviewHamper(item);
            },
           
            PreviewHamper(item, isPrinted) {

                if (this.PreviewWindow != null) {
                    
                    this.PreviewWindow.close();
                    this.PreviewWindow = null;
                }
                this.PreviewWindow = window.open("about:blank", item.Name);
            
                // newWin.document.write(
                //     "<script>window.opener.document.body.innerHTML = 'Test'<\/script>"
                // );
               
                let html = `<div class="container" style="border:2px solid red;padding-bottom:20px;max-width:100%;">`;
            html+=`<div class="row">
        <div class="col-10 mt-3 text-center"> Code: `+ item.Id + ` - Name: ` + item.Name + ` - Price no VAT: ` + this.formatMoney(item.Price) + ` vnd - Price with VAT: ` + this.formatMoney(item.Price * this.HamperVAT) + ` vnd </div>`

                if (isPrinted != 1)
                    html += `<div class="col-2 mt-3 text-end"> <button type="button" class="btn btn-primary" onclick="this.remove();window.print();window.close()">Print</button> </div>`;
      html+=`</div>`;




            html += `
            <div class="row">
        <div class="col-3">		
		<div class="container">		  
		  <div class="row">`;
          //========== boxes ============
                for (let i = 0; i < item.BoxIds.length; i++) {
                    html += `<div class="col-12  mt-3 text-center">
			  <img style="width:100%;" src="`+ this.dataBoxes[item.BoxIds[i]].HamperImage +`" />
			</div>`;
                }


                html += ` </div></div></div>
    
<div class="col-5">		
<div class="container">		  
  <div class="row">`;

            //========== Wines ============
                for (let i = 0; i < item.ProductDetailIds.length; i++) {
                    let wine = this.dataProductsDetail[item.ProductDetailIds[i]];                    
                    if (item.ProductDetailIds.length == 1)
                        html += `<div class="col-3 mt-3"></div>`;

                    let style = `margin-left: -200px;margin-right: 200px`;
                    if (wine.Height / wine.Width >= 2) style = ``;

                html += `<div class="col-6  mt-3 text-center">
			  <img style="height: 700px;`+ style +`" src="`+ this.dataProductsDetail[item.ProductDetailIds[i]].HamperImage + `" />
			</div>`;
                    if (item.ProductDetailIds.length == 1)
                        html += `<div class="col-3 mt-3"></div>`;
            }
                html += `</div>
		    </div>
        </div>`;




            html += `
        <div class="col-4">		
		<div class="container">		  
		  <div class="row">`;
                //========== Items ============
                let noimage = require(`@/assets/img/app/noimage.gif`);
                for (let i = 0; i < 6; i++) {

                html += `<div class="col-6  mt-3">
			  <img style="width:90%;" src="`+ (item.ItemIds[i] == undefined ? noimage:this.dataItems[item.ItemIds[i]].HamperImage) + `" />
			</div>`;
            }
                html += `</div>
            </div>
		</div>`;

                html += `</div>`;
                if (isPrinted==1)
                    html += `<script>window.print();window.close()<\/script>`;
            this.PreviewWindow.document.write(`<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">`);
            this.PreviewWindow.document.write(html);

            },

            Remove(item) {
                this.showModal({
                    isShow: true, title: 'Warning', content: "Are you sure to delete Hamper <br /><b>" + item.Name + "</b>?", type: 'error', callback: () => {

                        this.DoRemove(item);
                    }, isConfirm: true
                });
            },
            
            async DoRemove(item) {
                this.loading(true);

                const rs = await this.call({
                    Action: 'Hamper/Remove', Object: {
                        RemoveId: item.Id,
                    }
                })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    //==== refresh save
                    this.dataByZone = [];
                    this.doViewByOrder(this.OrderById);
                    //=======

                    setTimeout(() => { this.getItems(); }, 200);
                    
                    this.showToast({ isShow: true, title: 'Success', content: "Remove " + item.Name + " successful.", type: 'success' });
                    
                }
            },

            async getItems() {
                
                this.loading(true);
                const rs = await this.call({ Action: 'Hamper/GetItems', Object: { ZoneId: this.ZoneId } })
                
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    this.dataBoxes = rs.Data.Boxes;
                    this.dataProductsDetail = rs.Data.ProductsDetail;
                    this.dataItems = rs.Data.Items;
                    
                    this.data = rs.Data.Hampers;
                    this.mapBoxOrgPrice = rs.Data.mapBoxOrgPrice;
                    this.mapProdOrgPrice = rs.Data.mapProdOrgPrice;
                    this.mapItemOrgPrice = rs.Data.mapItemOrgPrice;
                    

                    for (let i = 0; i < this.data.length; i++) {                      
                        if (this.data[i].BoxIds == '' || this.data[i].BoxIds == null) this.data[i].BoxIds = [];
                        else this.data[i].BoxIds = this.data[i].BoxIds.split(',');
                        if (this.data[i].ItemIds == '' || this.data[i].ItemIds == null) this.data[i].ItemIds = [];
                        else this.data[i].ItemIds = this.data[i].ItemIds.split(',');
                        if (this.data[i].ProductDetailIds == '' || this.data[i].BoxIds == null) this.data[i].ProductDetailIds = [];
                        else this.data[i].ProductDetailIds = this.data[i].ProductDetailIds.split(',');
                    }
                    

                    this.mapProductNames = {};
                    for (let i = 0; i < rs.Data.Products.length; i++) {
                        this.mapProductNames[rs.Data.Products[i].Code] = rs.Data.Products[i];
                    }

                    try {
                        this.HamperDefaultItems = JSON.parse(rs.Data.HamperDefaultItems);
                    } catch (ex) { console.log(ex); }

                    try {
                        this.HamperDefaultBoxes = JSON.parse(rs.Data.HamperDefaultBoxes);
                    } catch (ex) { console.log(ex); }

                    this.mapProductType = rs.Data.ProductType;

                    this.mapProducts = {};
                    let _this = this;
                    const productKeys = Object.keys(this.dataProductsDetail);
                    for (let i = 0; i < productKeys.length; i++) {
                        let item = this.dataProductsDetail[productKeys[i]];
                        this.mapProducts[this.dataProductsDetail[productKeys[i]].Code] = this.dataProductsDetail[productKeys[i]];
                        if (item.HamperImage != "")
                            c.getMeta(
                                item.HamperImage,
                                (width, height) => { _this.dataProductsDetail[productKeys[i]].Width = width; _this.dataProductsDetail[productKeys[i]].Height = height; }
                            );

                    }
                    this.mapItems = {};
                    const itemKeys = Object.keys(this.dataItems);
                    for (let i = 0; i < itemKeys.length; i++) {
                        this.mapItems[this.dataItems[itemKeys[i]].Code] = this.dataItems[itemKeys[i]];
                    }
                    this.mapBoxes = {};
                    const boxKeys = Object.keys(this.dataBoxes);
                    for (let i = 0; i < boxKeys.length; i++) {
                        this.mapBoxes[this.dataBoxes[boxKeys[i]].Code] = this.dataBoxes[boxKeys[i]];
                    }
                    this.mapZones = { 0: {'Id':0,'Name':'-- All Regions --'} };
                    for (let i = 0; i < rs.Data.Zones.length; i++) {
                        this.mapZones[rs.Data.Zones[i].Id] = rs.Data.Zones[i];
                    }
                    this.doViewByZone(this.ZoneId);
                }
                this.loading(false);
            },
            
            doViewByZone(zoneId) {
                if (zoneId == undefined)
                    zoneId = 0;
                this.ZoneId = zoneId;
                this.dataByZone = [];
                let keys = Object.keys(this.data);
                for (let i = 0; i < this.data.length; i++) {                    
                    if (this.data[i].ZoneId == zoneId || zoneId==0)
                        this.dataByZone.push(this.data[i]);
                }
                this.doViewByOrder(this.OrderById);
                
            },
            doViewByOrder(orderId) {
       
                //1: { 'Id': 1, 'Description': 'Name Descending ↓' },
                //2: { 'Id': 2, 'Description': 'Name Ascending ↑' },
                //3: { 'Id': 3, 'Description': 'Price Descending ↓' },
                //4: { 'Id': 4, 'Description': 'Price Ascending ↑' },
                //5: { 'Id': 5, 'Description': 'Updated Descending ↓' },
                //6: { 'Id': 6, 'Description': 'Updated Ascending ↑' },
                
                if (orderId >= 0) {
                    this.OrderById = orderId;
                }

              

                if (orderId == 1) {
                    this.dataByZone.sort((a, b) => {
                        let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();
                        return nameB.localeCompare(nameA);

                    }); // b - a for reverse sort
                }
                else if (orderId == 2) {
                    this.dataByZone.sort((a, b) => {
                        let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();
                        return nameA.localeCompare(nameB);
                    }); // b - a for reverse sort
                }
                else if (orderId == 3) {
                    this.dataByZone.sort((a, b) => b.Price - a.Price); // b - a for reverse sort
                }
                else if (orderId == 4) {
                    this.dataByZone.sort((a, b) => a.Price - b.Price); // b - a for reverse sort
                }
                else if (orderId == 5) {
                    this.dataByZone.sort((a, b) => {
                        let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();
                       
                        return b.Updated.localeCompare(a.Updated);
                    }); // b - a for reverse sort
                }
                else if (orderId == 6) {
                    this.dataByZone.sort((a, b) => {
                        let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();
                        return a.Updated.localeCompare(b.Updated);
                    }); // b - a for reverse sort
                }
                else {
                    this.dataByZone.sort((a, b) => b.Id - a.Id); // b - a for reverse sort
                }
                
                
                
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                setTimeout(this.doTable, 100);
                
                
                //this.dataTable
                //    .order([columnIndex, sortType])
                //    .draw();
            },
            selectEditZone(zoneId) {
                this.editZoneId = zoneId;
                this.selectedBoxes = {};
                this.clearAllHamperItem();
                this.clearAllHamperItem(false);
            },
            doTable() {
                const _this = this;
                _this.dataTable = $('#datatable').DataTable({
                    responsive: false,
                    "ordering": false                  
                    
                });
                this.loading(false);
            },            
            validName() {
                return this.validTextField(this.Name, 'HamperName', true, /.*/, '', 2, 100, this.NameExisted, this.editData.Id);
            },
            validTextField(value, id, required, regex, regexMessage, minlen, maxlen, NameExisted, editedId) {
                if (!id || id == '') return;
                let error = "";
                minlen = minlen || 0;
                maxlen = maxlen || 1000;
                editedId = editedId || 0;
                const checkedvalue = value.trim();
                const isCheckRequire = required || (!required && checkedvalue.length > 0);

                if (required && checkedvalue == '') {
                    error = "Please input this field!";
                }
                //check name existed
                else if (NameExisted != null && NameExisted != undefined &&
                    (NameExisted[checkedvalue] != undefined && editedId != NameExisted[checkedvalue] || (Array.isArray(NameExisted) && NameExisted.includes(checkedvalue)))
                ) {
                    error = id + " is duplicated!";
                }
                //check pattern
                else if (isCheckRequire && regex != null && regex != undefined && !regex.test(checkedvalue))
                    error = regexMessage || `Invalid ${id}!`;
                //check len

                else if (isCheckRequire && (checkedvalue.length < minlen)) {
                    error = `Please input at least ${minlen} characters!`;
                }
                else if (checkedvalue.length > maxlen) {
                    error = `Please do not exceed ${maxlen} characters!`;
                }

                //return value
                if (error == '') {
                    if ($('#' + id).length > 0) {
                        $('#' + id).removeClass('is-invalid');
                        $('#' + id).addClass('is-valid');
                    }
                    return true;
                } else {
                    if ($('#' + id).length > 0) {
                        $('#' + id + '~.invalid-feedback')[0].innerHTML = error;
                        $('#' + id).addClass('is-invalid');
                        $('#' + id).removeClass('is-valid');
                    }
                    $('#' + id).focus();
                    return false;
                }
            },
            async SaveEdit() {                
                if (!this.validName()) {
                    this.showToast({ isShow: true, title: '', content: "Form is invalid!", type: 'error' });
                    return;
                }
                if (this.editZoneId == 0) {
                    this.showToast({ isShow: true, title: '', content: "Please select a Region!", type: 'error' });
                    return;
                }
                if (this.selectedBoxes.length==0) {
                    this.showToast({ isShow: true, title: '', content: "Please select a box!", type: 'error' });
                    return;
                }
                this.loading(true);

                const rs = await this.call({
                    Action: 'Hamper/Save', Object: {
                        Id: this.editId,
                        Name: this.Name,
                        ShowGuest: this.ShowGuest,
                        SelectedBoxes: this.selectedBoxes,
                        SelectedProducts: this.selectedProducts,
                        SelectedItems: this.selectedItems,
                        Price: this.HamperPrice,
                        ZoneId: this.editZoneId,
                        PreviewLink: this.PreviewLink,
                    }, Files: this.FileUpload
                })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {                    
                    //==== refresh save
                    this.dataByZone = [];
                    this.doViewByOrder(this.OrderById);
                    //=======

                    setTimeout(() => { this.getItems(); }, 200);

                    this.showToast({ isShow: true, title: '', content: 'Save Hamper Successful!', type: 'success' });
                    
                    
                    this.showEditHamper = false;
                }
            },

            

            //====================================================================
            //====================================================================
            //====================================================================
            //=================                               ====================
            //=================   setup hamper default items ====================
            //=================                               ====================
            //====================================================================
            //====================================================================
            //====================================================================

            selectHamperDefaultItem(isSelectBox) {
                if (isSelectBox == undefined) isSelectBox = true;
                if (!this.checkAcl('DefaultItems')) return;
                if (this.ZoneId == 0) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a Region", type: 'error' });
                    return;
                }         
                if (isSelectBox) this.selectBoxTitle = "Please select default boxes for hampers";
                else this.selectBoxTitle = "Please select default items for hampers";
                this.dataBoxesAvailable = [];
                let objs = {};
                let datas = {};                
                if (isSelectBox) {
                    datas = _.cloneDeep(this.dataBoxes);                    
                    this.HamperDefaultItemsPopup = this.HamperDefaultBoxes;
                }
                else {
                    datas = _.cloneDeep(this.dataItems);
                    this.HamperDefaultItemsPopup = this.HamperDefaultItems;
                }
                objs = Object.keys(datas);
                for (let i = 0; i < objs.length; i++) {  
                    let item = datas[objs[i]];
                    if (item.ProductTypeId != null) item.Name += ' (' + this.mapProductType[item.ProductTypeId].Description + ' )';
                    if (item.ZoneId == this.ZoneId) {
                        this.dataBoxesAvailable.push(item);
                    }
                }
                
                //sorting
                this.dataBoxesAvailable.sort((a, b) => b.Price - a.Price);
                this.showHamperDefaultItemsSelect = true;
            },
            setDefaultItem(itemcode, isBoxes) {
                if (isBoxes == undefined) isBoxes = true;

                if (isBoxes) {                    
                    if (this.HamperDefaultBoxes[this.ZoneId] == undefined)
                    this.HamperDefaultBoxes[this.ZoneId] = [];
                    if (this.HamperDefaultBoxes[this.ZoneId].includes(itemcode))
                        _.remove(this.HamperDefaultBoxes[this.ZoneId], function (n) {
                                return n == itemcode;
                            });
                    else this.HamperDefaultBoxes[this.ZoneId].push(itemcode);
                }
                else {
                    if (this.HamperDefaultItems[this.ZoneId] == undefined)
                        this.HamperDefaultItems[this.ZoneId] = [];
                    if (this.HamperDefaultItems[this.ZoneId].includes(itemcode))
                        _.remove(this.HamperDefaultItems[this.ZoneId], function (n) {
                            return n == itemcode;
                        });
                    else this.HamperDefaultItems[this.ZoneId].push(itemcode);
                }
            },

            async saveHamperDefaultItems(isBoxes) {
                if (isBoxes == undefined) isBoxes = true;
                this.loading(true);
                let action = "SaveDefaultItems";
                if (isBoxes) action = "SaveDefaultBoxes";            
                const rs = await this.call({
                    Action: 'Hamper/'+action, Object: {
                        HamperDefaultItems: JSON.stringify(this.HamperDefaultItems),
                        HamperDefaultBoxes: JSON.stringify(this.HamperDefaultBoxes),
                    }
                })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    return;
                } 
                this.showHamperDefaultItemsSelect = false;
            },

        //====================================================================
        //====================================================================
        //====================================================================
        //=================                               ====================
        //=================   setup hamper wine and items ====================
        //=================                               ====================
        //====================================================================
        //====================================================================
        //====================================================================
            selectHamperBox(isSelectBox, isSelectProduct) {
                if (this.editZoneId == 0) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a Region", type: 'error' });
                    return;
                }
                if (isSelectBox == undefined) isSelectBox = true;
                if (isSelectProduct == undefined) isSelectProduct = false;
                this.selectBoxTitle = "Please select an item";
                if (isSelectBox)
                    this.selectBoxTitle = "Please select a box";
                else if (isSelectProduct)
                    this.selectBoxTitle = "Please select a wine";
                this.isSelectBox = isSelectBox;
                this.isSelectProduct = isSelectProduct;

                this.dataBoxesAvailable = [];
                let objs = {};
                let datas = {};
                if (isSelectBox) {                
                    datas = _.cloneDeep(this.dataBoxes);                    
                } else if (isSelectProduct) {
                    datas = _.cloneDeep(this.dataProductsDetail); 
                } else {
                    datas = _.cloneDeep(this.dataItems);
                }
                objs = Object.keys(datas);

                for (let i = 0; i < objs.length; i++) {                
                    let item = datas[objs[i]];
                    let isAdded = false;
                    

                    if (isSelectProduct) {
                        item.Name = this.mapProductNames[item.Code].Name;                        
                        isAdded = true;                     
                    }
                    else if (item.ZoneId == this.editZoneId) {
                        //if (this.dataBoxes[i].HamperSizeX * this.dataBoxes[i].HamperSizeY > this.hamperVolume)                        
                        isAdded = true;                        
                    }
                    if (isAdded) {
                        if (item.ProductTypeId != null) item.Name += ' (' + this.mapProductType[item.ProductTypeId].Description + ' )';
                        this.dataBoxesAvailable.push(item);
                    }

                }
                //sorting
                this.dataBoxesAvailable.sort((a, b) => b.Price - a.Price);
                this.showHamperBoxSelect = true;
            },
            clearAllHamperItem(isWine) {
                if (isWine == undefined) isWine = true;
                if (isWine)
                    this.selectedProducts = [];
                else
                    this.selectedItems = [];   
                this.recalculateHamperPrice();
            },
            clearAllHamperBoxes() {
                this.selectedBoxes = [];   
                this.recalculateHamperPrice();
            },
            recalculateHamperPrice() {
                //recalculate hamper price
                
                this.HamperPrice = 0;
                this.HamperPriceVAT = 0;
                this.HamperOrgPrice = 0;

                if (this.selectedBoxes.length > 0) {
                    
                    this.selectedBoxes.forEach(item => {
                        //if (item.PriceVat != null) this.HamperPrice += item.PriceVat * 1;
                        //else
                        this.HamperPrice += item.Price;
                        this.HamperOrgPrice += this.mapBoxOrgPrice[item.Code];
                    });
                }
               
                if (this.selectedProducts.length > 0) {
                    
                    this.selectedProducts.forEach(item => {
                        
                        //if (item.PriceVat != null) this.HamperPrice += item.PriceVat * 1;
                        //else
                        this.HamperPrice += item.Price;
                        this.HamperOrgPrice += this.mapProdOrgPrice[item.Code];
                        
                    });                    
                }
                if (this.selectedItems.length > 0) {
                    
                    this.selectedItems.forEach(item => {
                        //if (item.PriceVat != null) this.HamperPrice += item.PriceVat * 1;
                        //else
                        this.HamperPrice += item.Price;
                        this.HamperOrgPrice += this.mapItemOrgPrice[item.Code];
                    });
                }

                this.HamperPriceVAT = this.HamperPrice * this.HamperVAT;
                
            },
            SelectBox(item) {
                this.lastNumberAdded++;
                item = JSON.parse(JSON.stringify(item));
                item.NumberAdded = this.lastNumberAdded;
                //console.log(item);
                if (!this.HamperPrice || isNaN(this.HamperPrice)) {
                    this.HamperPriceVAT = 0;
                    this.HamperPrice = 0;
                }
                if (this.isSelectBox) {
                    this.selectedBoxes.push(item);
                }
                else if (this.isSelectProduct) {
                    this.selectedProducts.push(item);
                }
                else {
                    this.selectedItems.push(item);
                }
                
                //if (item.PriceVat!=null) this.HamperPrice += item.PriceVat * 1;
                //else this.HamperPrice += item.Price * 1;
                //this.HamperPriceVAT = this.HamperPrice * this.HamperVAT;

                this.recalculateHamperPrice();
                this.showHamperBoxSelect = false;
            },


            removeBoxItem(item, isRemoveProduct) {
                if (isRemoveProduct == undefined) isRemoveProduct = true;
                if (isRemoveProduct) {
                    _.remove(this.selectedProducts, function (n) {
                        return n.NumberAdded == item.NumberAdded;
                    });                    
                } else {
                    _.remove(this.selectedItems, function (n) {
                        return n.NumberAdded == item.NumberAdded;
                    });                    
                }
                this.recalculateHamperPrice();
            },
            removeBoxFromHamper(item) {
            
                _.remove(this.selectedBoxes, function (n) {
                    return n.NumberAdded == item.NumberAdded;
                });            
                this.recalculateHamperPrice();
        },

            //====================================================================
            //====================================================================
            //====================================================================
            //=================                            =======================
            //=================   Auto suggestion items    =======================
            //=================                            =======================
            //====================================================================
            //====================================================================
            //====================================================================

            ShowAutoSuggest() {
                if (this.editZoneId == 0) {
                    this.showToast({ isShow: true, title: '', content: "Please select a Region!", type: 'error' });
                    return;
                }
                this.dataSuggest = [];
                this.sliderwine.min = this.selectedProducts.length;
                this.sliderwine.value = this.selectedProducts.length < 2 ? 2 : this.selectedProducts.length;
                this.slideritem.min = this.selectedItems.length;
                this.slideritem.value = 5;
                
                let range = 1000000;                      
                let minrange = Math.floor(this.HamperPrice / this.sliderprice.step) * this.sliderprice.step;                                
                this.sliderprice.min = minrange;
                this.sliderprice.value = [minrange, minrange > this.sliderprice.max - range ? minrange + this.sliderprice.step : minrange * 1 + range];                
                this.showHamperAutoSuggest = true;
            },
            GetSuggest() {
                this.loading(true);
                setTimeout(this.doGetSuggest,200);
            },
            async doGetSuggest() {
                if (this.NumOfSuggest <= 0) this.NumOfSuggest = 5;
                
                let tryGet = 0;
                let maxTryGet = 100;
                let wineKeys = Object.keys(this.dataProductsDetail);

                
                let datakeys = Object.keys(this.dataItems);
                let itemKeys = [];
                for (let i = 0; i < datakeys.length; i++) {
                    if (this.dataItems[datakeys[i]].ZoneId == this.editZoneId)
                        itemKeys.push(datakeys[i]);
                }
                
                let ran = 0;
                let totalPrice = 0;
                this.dataSuggest = [];

                let selectedProductIds = [];                
                this.selectedProducts.forEach(item => {                                        
                        selectedProductIds.push(item.Id);
                })
                let selectedItemIds = [];
                this.selectedItems.forEach(item => {
                        selectedItemIds.push(item.Id);
                })
                console.log(this.HamperPrice);
                let suggestId = 0;
                let isTooLow = false;
                while (tryGet < this.NumOfSuggest * maxTryGet) {
                    //check break condition
                    if (this.dataSuggest.length >= this.NumOfSuggest) break;
                    suggestId++;
                    //init data
                    totalPrice = this.HamperPrice * 1;
                    let suggestItem = { Id: suggestId, ProductDetailIds: JSON.parse(JSON.stringify(selectedProductIds)), ItemIds: JSON.parse(JSON.stringify(selectedItemIds)), OrgPrice: this.HamperOrgPrice, Price: 0, PriceVAT: 0 };
                    
                    //check the same for pre-selected product
                    if (this.AllSameWine && selectedProductIds.length > 0) {
                        for (let n = suggestItem.ProductDetailIds.length; n < this.sliderwine.value; n++) {
                            var randomItem = this.dataProductsDetail[selectedProductIds[Math.floor(Math.random() * (selectedProductIds.length - 0) + 0)]];
                            suggestItem.ProductDetailIds.push(randomItem.Id);
                            //if (randomItem.PriceVat != null) totalPrice += randomItem.PriceVat * 1;
                            //else
                            totalPrice += randomItem.Price * 1;
                            
                            suggestItem.OrgPrice += this.mapProdOrgPrice[randomItem.Code];
                        }
                    }


                    //get suggest wines                    
                    let zeroNum = 0;
                    if (wineKeys.length > 0) {
                        while (suggestItem.ProductDetailIds.length < this.sliderwine.value) {
                            ran = Math.floor(Math.random() * (wineKeys.length - 0) + 0);
                            //if (wineAdded.includes(ran) && !this.suggestDuplicate) continue;
                            let item = this.dataProductsDetail[wineKeys[ran]];
                            
                            if (item.Price == 0) {
                                
                                if (this.zeroPrizeWine==false) {
                                    continue;
                                }
                                else if (zeroNum >= this.zeroPrizeWineNumber)
                                    continue;
                                zeroNum++;
                            }
                            
                            suggestItem.ProductDetailIds.push(item.Id);
                            
                            //if (item.PriceVat != null) totalPrice += item.PriceVat * 1;
                            //else
                            suggestItem.OrgPrice += this.mapProdOrgPrice[item.Code];
                            totalPrice += item.Price;

                            if (this.AllSameWine) {
                                for (let n = suggestItem.ProductDetailIds.length; n < this.sliderwine.value; n++) {
                                    suggestItem.ProductDetailIds.push(item.Id);                                                                
                                    //if (item.PriceVat != null) totalPrice += item.PriceVat * 1;
                                    //else
                                    totalPrice += item.Price;
                                    suggestItem.OrgPrice += this.mapProdOrgPrice[item.Code];
                                }
                            }
                        }
                    }
                    //get suggest items
                    let itemAdded = [];
                    if (itemKeys.length > 0) {
                        while (suggestItem.ItemIds.length < this.slideritem.value) {
                            ran = Math.floor(Math.random() * (itemKeys.length - 0) + 0);
                            if (itemAdded.includes(ran)) continue;
                            let item = this.dataItems[itemKeys[ran]];
                            suggestItem.ItemIds.push(item.Id);
                            
                            //if (item.PriceVat != null) totalPrice += item.PriceVat * 1;
                            //else
                            totalPrice += item.Price;
                            suggestItem.OrgPrice += this.mapItemOrgPrice[item.Code];
                            itemAdded.push(ran);
                        }
                    }
                    suggestItem.Price = totalPrice;
                    suggestItem.PriceVAT = totalPrice * this.HamperVAT;
                    
                    if (totalPrice >= this.sliderprice.value[0] && totalPrice <= this.sliderprice.value[1])
                        this.dataSuggest.push(JSON.parse(JSON.stringify(suggestItem)));
                    else {
                        if (totalPrice < this.sliderprice.value[0])
                            isTooLow = true;
                    }
                    tryGet++;
                }
                this.loading(false);
                if (this.dataSuggest.length == 0)
                    if (isTooLow)
                        this.showToast({ isShow: true, title: 'Warning', content: "Can not get suggest, please increase number of item or decrease the price of hamper.", type: 'error' });
                    else
                        this.showToast({ isShow: true, title: 'Warning', content: "Can not get suggest, please decrease number of item or increase the price of hamper.", type: 'error' });
                else {
                    //sorting result
                    this.dataSuggest.sort((a, b) => b.Price - a.Price); // b - a for reverse sort

                }
                        
            },
            SelectSuggest(item) {
                this.HamperPrice = item.Price;
                this.HamperPriceVAT = item.PriceVAT;
                this.selectedProducts = [];
                for (let i = 0; i < item.ProductDetailIds.length; i++) {
                    if (item.ProductDetailIds[i] != '') {
                        this.lastNumberAdded++;
                        let itemadd = JSON.parse(JSON.stringify(this.dataProductsDetail[item.ProductDetailIds[i]]));
                        itemadd.NumberAdded = this.lastNumberAdded;
                        this.selectedProducts.push(itemadd);
                    }
                }
                this.selectedItems = [];
                for (let i = 0; i < item.ItemIds.length; i++) {
                    if (item.ItemIds[i] != '') {
                        this.lastNumberAdded++;
                        let itemadd = JSON.parse(JSON.stringify(this.dataItems[item.ItemIds[i]]));
                        itemadd.NumberAdded = this.lastNumberAdded;
                        this.selectedItems.push(itemadd);
                    }
                }
                this.recalculateHamperPrice();
                this.showHamperAutoSuggest = false;
            }
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
            
           
            
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            },

        }
    };
</script>
